import {StyleParamType, createStylesParams, getSettingsValues} from '@wix/tpa-settings'

import {layoutSettingsParams} from '../../../../ReservationAddOn/Settings/Tabs/Layout/settingsParams'
import {FormLayout} from '../../../../../types/createStylesParams'

export type LayoutStylesParams = {
  spaceBetweenTitleAndDescription: StyleParamType.Number
  spaceBetweenTextAndForm: StyleParamType.Number
  spaceBetweenFormAndTimeSlots: StyleParamType.Number
  sidePadding: StyleParamType.Number
  topBottomPadding: StyleParamType.Number
  buttonPadding: StyleParamType.Number
}

export const layoutStylesParams = createStylesParams<LayoutStylesParams>({
  spaceBetweenTitleAndDescription: {
    type: StyleParamType.Number,
    inheritDesktop: false,
    getDefaultValue: () => 20,
  },
  spaceBetweenTextAndForm: {
    type: StyleParamType.Number,
    inheritDesktop: false,
    getDefaultValue: (params) => (params.isMobile ? 40 : 80),
  },
  spaceBetweenFormAndTimeSlots: {
    type: StyleParamType.Number,
    inheritDesktop: false,
    getDefaultValue: (params) => {
      const layoutSettings = getSettingsValues({}, layoutSettingsParams)
      const isFormStripLayout = layoutSettings.formLayout === FormLayout.Strip
      const value = isFormStripLayout ? 80 : 120
      return params.isMobile ? 40 : value
    },
  },
  sidePadding: {
    type: StyleParamType.Number,
    inheritDesktop: false,
    getDefaultValue: (params) => (params.isMobile ? 0 : 40),
  },
  topBottomPadding: {
    type: StyleParamType.Number,
    inheritDesktop: false,
    getDefaultValue: (params) => (params.isMobile ? 20 : 40),
  },
  buttonPadding: {
    type: StyleParamType.Number,
    inheritDesktop: false,
    getDefaultValue: () => 20,
  },
})
