import {useCallback, useEffect, useMemo, useState} from 'react'
import {DropdownOptionProps} from 'wix-ui-tpa/cssVars'
import {useBi, useEnvironment, useTranslation} from '@wix/yoshi-flow-editor'
import {Status, TimeSlot} from '@wix/ambassador-table-reservations-v1-time-slot/types'
import {useSettings} from '@wix/tpa-settings/react'
import {ApprovalMode} from '@wix/ambassador-table-reservations-v1-reservation-location/types'
import {setHMToDate} from '@wix/table-reservations-lib/schedule'

import {useReservationsStorage} from '../storage'
import {filterDate, getNearestWorkingDate} from '../../../utils/date'
import {getBusinessScheduleFromReservationLocation} from '../../../utils/businessSchedule'
import {findTimeOption, getTimeOptions, TimeOption} from '../../../utils/timeOptions'
import {
  getInitialPartySizeFromOptions,
  getPartySizeOptionsForReservationLocation,
} from '../../../utils/partySize'
import {EDITOR_MOCK_RESERVATION_LOCATION_ID} from '../../../editor/editorMocks/getReservationLocationsMock'
import {FieldName, PageType, getLogger} from '../../../utils/getLogger'
import {
  getEnabledReservationLocations,
  getInitialReservationLocation,
  getReservationLocationById,
  getReservationLocationsOptions,
} from '../../../utils/reservationLocation'
import {scrollTo} from '../../../utils/scroll'
import {RequestStatus} from '../../../utils/wrapRequest'
import {reservationsFormDataHooks} from '../components/Form/constants'
import {TimeSlotsByDate} from '../../../types/TimeSlots'
import {useElementWidth} from '../../../utils/useElementWidth'
import {reserveButtonDataHooks} from '../components/ReserveButton/constants'
import {useGetLayoutSize} from '../../../components-shared/LayoutSizeProvider'
import {useApproveTextView} from '../../../utils/useApproveTextView'
import settingsParams from '../settingsParams'
import {getPreselectedDate} from '../../../utils/getPreselectedDate'

import {ReservationsPageType, SlotsSettingToStatus} from './constants'

export const useHooks = () => {
  const {t} = useTranslation()
  const bi = useBi()
  const logger = getLogger(bi)
  const {width: submitButtonContentWidth, readAndSetWidth: readAndSetSubmitButtonContentWidth} =
    useElementWidth<HTMLSpanElement>(reserveButtonDataHooks.button())

  const {layoutSize, containerWidth} = useGetLayoutSize()

  const {
    regionalSettings,
    getReservationLocationsStatus,
    getTimeSlotsStatus,
    reservationLocations,
    timeSlots,
    dayTimeSlots,
    selectedReservationLocationId: preselectedReservationLocationId,
    selectedPartySize: preselectedPartySize,
    selectedDate: preselectedDate,
    getTimeSlots,
    getDayTimeSlots,
    getDayTimeSlotsStatus,
    prefetchCurrentMember,
    handleReservationData,
    handleReservationDataStatus,
    metaSiteId,
    queryValidation,
    approvalTextEditorState,
    isSiteBusinessPremium,
    breadcrumbsConfig,
  } = useReservationsStorage()
  const isQueryValid =
    queryValidation.startDate && queryValidation.partySize && queryValidation.reservationLocationId
  const {isEditor, isPreview, isEditorX} = useEnvironment()
  const settings = useSettings()
  const showSlotsBehavior = settings.get(settingsParams.showSlotsBehavior)

  const isNoReservationLocations = !reservationLocations.length

  const enabledReservationLocations = getEnabledReservationLocations(reservationLocations)

  // reservations unavailable
  const [shouldShowReservationsUnavailable, setShouldShowReservationsUnavailable] = useState(false)

  const handleHideReservationsUnavailable = useCallback(() => {
    setShouldShowReservationsUnavailable(false)
  }, [])

  // reservation location
  const [selectedReservationLocation, setSelectedReservationLocation] = useState(
    getInitialReservationLocation(reservationLocations, preselectedReservationLocationId),
  )

  const reservationLocationsOptions: DropdownOptionProps[] = getReservationLocationsOptions(
    enabledReservationLocations,
    t,
  )

  // businessSchedule
  const businessSchedule = useMemo(
    () => getBusinessScheduleFromReservationLocation(selectedReservationLocation),
    [selectedReservationLocation],
  )

  // party size
  const partySizeOptions = useMemo(
    () => getPartySizeOptionsForReservationLocation(t, selectedReservationLocation),
    [selectedReservationLocation],
  )

  const [partySize, setPartySize] = useState(
    preselectedPartySize ?? getInitialPartySizeFromOptions(partySizeOptions),
  )

  // date
  const validPreselectedDate = getPreselectedDate({
    selectedReservationLocation,
    preselectedReservationLocationId,
    preselectedDate,
    queryValidation,
  })

  const [selectedDate, setSelectedDate] = useState<Date>(
    () =>
      validPreselectedDate ??
      getNearestWorkingDate({
        businessSchedule,
        regionalSettings,
        timeZone: selectedReservationLocation?.location?.timeZone,
      }),
  )

  // time
  const timeOptions = useMemo(
    () => getTimeOptions({timeSlots: dayTimeSlots, regionalSettings}),
    [dayTimeSlots, regionalSettings],
  )
  const [selectedTime, setSelectedTime] = useState<TimeOption | undefined>(() =>
    findTimeOption(timeOptions, selectedDate),
  )

  // manual approval
  const [isManualApproval, setIsManualApproval] = useState(false)

  useEffect(() => {
    const manualApprovalConfig =
      selectedReservationLocation?.configuration?.onlineReservations?.approval

    const partySizeThreshold =
      manualApprovalConfig?.manualForLargePartiesOptions?.partySizeThreshold || 0

    const shouldBeManualApprove =
      manualApprovalConfig?.mode === ApprovalMode.MANUAL ||
      (manualApprovalConfig?.mode === ApprovalMode.MANUAL_FOR_LARGE_PARTIES &&
        partySize >= partySizeThreshold)

    setIsManualApproval(shouldBeManualApprove)
  }, [partySize, selectedReservationLocation])

  // time slot
  const [selectedTimeSlot, setSelectedTimeSlot] = useState<Date>()
  const [showSelectTimeSlotError, setShowSelectTimeSlotError] = useState(false)
  const [showNoAvailableTimeSlotNotification, setShowNoAvailableTimeSlotNotification] =
    useState(false)

  // BI
  const clickOnReservationsAttributesBiParams = useMemo(
    () => ({
      isPreview,
      isAddOn: false,
      isMultiLocation: !!enabledReservationLocations.length,
      locationId: selectedReservationLocation?.location?.id,
      reservationLocationId: selectedReservationLocation?.id,
    }),
    [enabledReservationLocations.length, isPreview, selectedReservationLocation],
  )

  const handleFilterDate = useCallback(
    (date: Date) =>
      filterDate({
        date,
        businessSchedule,
        timeZone: selectedReservationLocation?.location?.timeZone,
        regionalFormat: regionalSettings,
      }),
    [businessSchedule, selectedReservationLocation?.location?.timeZone, regionalSettings],
  )

  const handleLocationChange = (option: DropdownOptionProps) => {
    const newSelectedReservationLocation =
      getReservationLocationById(option.id!, enabledReservationLocations) ??
      enabledReservationLocations[0]

    setSelectedReservationLocation(newSelectedReservationLocation)

    logger.clickOnReservationsAttributes({
      ...clickOnReservationsAttributesBiParams,
      locationId: newSelectedReservationLocation?.location?.id,
      reservationLocationId: newSelectedReservationLocation?.id,
      fieldName: FieldName.location,
    })

    if (
      partySize <
        newSelectedReservationLocation?.configuration?.onlineReservations?.partySize?.min! ||
      partySize > newSelectedReservationLocation?.configuration?.onlineReservations?.partySize?.max!
    ) {
      setPartySize(
        newSelectedReservationLocation?.configuration?.onlineReservations?.partySize?.min!,
      )
    }
  }

  const handlePartySizeChange = (option: DropdownOptionProps) => {
    logger.clickOnReservationsAttributes({
      ...clickOnReservationsAttributesBiParams,
      fieldName: FieldName.partySize,
    })

    setPartySize(Number(option.id))
    loadDayTimeSlots(selectedDate)
  }

  const handleDateChange = (newDate: Date) => {
    logger.clickOnReservationsAttributes({
      ...clickOnReservationsAttributesBiParams,
      fieldName: FieldName.date,
    })

    setSelectedDate(newDate)
    setSelectedTime(undefined)
    setSelectedTimeSlot(undefined)

    loadDayTimeSlots(newDate)
  }

  const handleTimeChange = (newSelectedTime: TimeOption) => {
    logger.clickOnReservationsAttributes({
      ...clickOnReservationsAttributesBiParams,
      fieldName: FieldName.time,
    })

    setSelectedTime(newSelectedTime)
    setSelectedTimeSlot(undefined)

    loadTimeSlots(
      setHMToDate(selectedDate, newSelectedTime.data.hours, newSelectedTime.data.minutes),
    )
  }

  const handleTimeSlotChange = (item: TimeSlot) => {
    if (item.startDate) {
      setSelectedTimeSlot(item.startDate)
      setShowSelectTimeSlotError(false)
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    readAndSetSubmitButtonContentWidth()

    if (isInitialDataInProgress || handleReservationDataStatus === RequestStatus.LOADING) {
      return
    }

    if (!selectedTimeSlot) {
      setShowSelectTimeSlotError(true)
      return
    }

    if (!selectedReservationLocation?.id) {
      return
    }

    if (!enabledReservationLocations.length || !isSiteBusinessPremium) {
      setShouldShowReservationsUnavailable(true)
      return
    }

    logger.clickOnReserveNow({
      locationId: selectedReservationLocation.location?.id!,
      reservationLocationId: selectedReservationLocation.id,
      isPreview,
      partySize,
      requestedDate: selectedTimeSlot,
    })

    handleReservationData({
      details: {
        reservationLocationId: selectedReservationLocation.id,
        partySize,
        startDate: selectedTimeSlot,
      },
      timeZone: selectedReservationLocation?.location?.timeZone!,
      isManualApproval,
    })
  }

  const handleSearchAgainClick = () => {
    if (selectedTime) {
      loadTimeSlots(setHMToDate(selectedDate, selectedTime.data.hours, selectedTime.data.minutes))
      scrollTo(reservationsFormDataHooks.root())
    }
  }

  const loadTimeSlots = (date?: Date) => {
    if (!selectedReservationLocation?.id || !date) {
      return
    }

    getTimeSlots({
      reservationLocationId: selectedReservationLocation.id,
      date,
      partySize,
      timeSlotInterval:
        selectedReservationLocation.configuration?.onlineReservations?.timeSlotInterval,
      statuses: SlotsSettingToStatus[showSlotsBehavior],
      timeZone: selectedReservationLocation?.location?.timeZone,
    })
  }

  const loadDayTimeSlots = (date?: Date) => {
    if (!selectedReservationLocation?.id || !date) {
      return
    }

    getDayTimeSlots({
      reservationLocationId: selectedReservationLocation.id,
      date,
      partySize,
      timeSlotInterval:
        selectedReservationLocation.configuration?.onlineReservations?.timeSlotInterval,
      timeZone: selectedReservationLocation?.location?.timeZone,
    })
  }

  useEffect(() => {
    const todaySlots = timeSlots[Object.keys(timeSlots)[0]]

    if (!todaySlots || !selectedTime) {
      return setShowNoAvailableTimeSlotNotification(false)
    }

    const selectedDateAndTime = setHMToDate(
      selectedDate,
      selectedTime.data.hours,
      selectedTime.data.minutes,
    )

    const targetSlot = todaySlots.find(
      (timeSlot) => timeSlot.startDate?.getTime() === selectedDateAndTime?.getTime(),
    )

    if (
      (!targetSlot || targetSlot.status !== Status.AVAILABLE) &&
      getTimeSlotsStatus === RequestStatus.RESOLVED
    ) {
      return setShowNoAvailableTimeSlotNotification(true)
    }

    setShowNoAvailableTimeSlotNotification(false)
  }, [timeSlots, selectedDate, selectedTime, getTimeSlotsStatus])

  const handleErrorClick = () => {
    window.location.href = window.location.href.split('?')[0]
  }

  const {shouldShowAutoApproveText} = useApproveTextView(
    enabledReservationLocations,
    approvalTextEditorState,
  )

  const isInitialDataInProgress =
    getReservationLocationsStatus !== RequestStatus.RESOLVED ||
    getDayTimeSlotsStatus !== RequestStatus.RESOLVED ||
    getTimeSlotsStatus !== RequestStatus.RESOLVED

  const isLargeParty =
    selectedReservationLocation?.configuration?.onlineReservations?.partySize?.max &&
    partySize > selectedReservationLocation.configuration.onlineReservations.partySize.max

  const phoneNumber = selectedReservationLocation?.location?.phone
    ? selectedReservationLocation.location.phone
    : undefined

  const shouldShowNoLocationsEditorWarning =
    (isEditor || isPreview) &&
    enabledReservationLocations[0].id === EDITOR_MOCK_RESERVATION_LOCATION_ID

  const reservationsPageType = useMemo(
    () => getReservationsPageType(timeSlots, selectedDate),
    [timeSlots],
  )

  const shouldShowSearchAgainButton =
    reservationsPageType === ReservationsPageType.EXTENDED || isEditor

  useEffect(() => {
    logger.isLoaded({
      isAddOn: false,
      isPreview,
      pageType: PageType.page,
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const newSelectedDate =
      validPreselectedDate ??
      getNearestWorkingDate({
        businessSchedule,
        regionalSettings,
        timeZone: selectedReservationLocation?.location?.timeZone,
      })

    setSelectedDate(newSelectedDate)
    loadDayTimeSlots(newSelectedDate)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedReservationLocation, businessSchedule, showSlotsBehavior])

  useEffect(() => {
    const preselectedTimeOption = validPreselectedDate
      ? findTimeOption(timeOptions, validPreselectedDate)
      : undefined

    const newSelectedTime =
      preselectedTimeOption ??
      timeOptions.find(({data}) => data.status === Status.AVAILABLE) ??
      timeOptions[0]

    if (newSelectedTime) {
      setSelectedTime(newSelectedTime)
      loadTimeSlots(
        setHMToDate(selectedDate, newSelectedTime.data.hours, newSelectedTime.data.minutes),
      )
    }
  }, [timeOptions])

  // should trigger time slots load for Editor X or Wix Studio
  // (Editor reinitialize controller but doesn't reinitialize the component)
  useEffect(() => {
    const shouldTriggerTimeSlotsLoad =
      isEditorX &&
      !Object.keys(timeSlots).length &&
      getTimeSlotsStatus === RequestStatus.DEFAULT &&
      getDayTimeSlotsStatus === RequestStatus.DEFAULT

    if (shouldTriggerTimeSlotsLoad) {
      loadTimeSlots(selectedDate)
      loadDayTimeSlots(selectedDate)
    }
  }, [
    isEditorX,
    timeSlots,
    getTimeSlotsStatus,
    getDayTimeSlotsStatus,
    loadTimeSlots,
    loadDayTimeSlots,
  ])

  useEffect(() => {
    prefetchCurrentMember()
  }, [prefetchCurrentMember])

  return {
    t,
    submitButtonContentWidth,
    isPreview,
    layoutSize,
    containerWidth,
    phoneNumber,
    regionalSettings,
    isLargeParty,
    isInitialDataInProgress,
    getTimeSlotsStatus,
    handleReservationDataStatus,
    reservationsPageType,
    partySizeOptions,
    timeOptions,
    partySize,
    selectedDate,
    selectedTime,
    selectedTimeSlot,
    timeSlots,
    handlePartySizeChange,
    handleDateChange,
    handleTimeChange,
    handleTimeSlotChange,
    filterDate: handleFilterDate,
    handleSubmit,
    reservationLocationsOptions,
    selectedReservationLocation,
    handleLocationChange,
    shouldShowNoLocationsEditorWarning,
    metaSiteId,
    showSelectTimeSlotError,
    handleSearchAgainClick,
    isQueryValid,
    handleErrorClick,
    shouldShowSearchAgainButton,
    shouldShowReservationsUnavailable,
    handleHideReservationsUnavailable,
    isNoReservationLocations,
    shouldShowAutoApproveText,
    showNoAvailableTimeSlotNotification,
    breadcrumbsConfig,
  }
}

const getReservationsPageType = (
  timeSlotsByDays: TimeSlotsByDate,
  selectedDate?: Date,
): ReservationsPageType => {
  const daysCount = Object.keys(timeSlotsByDays).length

  if (daysCount === 0) {
    return ReservationsPageType.EMPTY
  }

  if (daysCount === 1) {
    const date = Object.keys(timeSlotsByDays)[0]
    return date !== selectedDate?.toDateString()
      ? ReservationsPageType.EXTENDED
      : ReservationsPageType.DEFAULT
  }

  return ReservationsPageType.EXTENDED
}
