import React, {useState} from 'react'
import {useTranslation} from '@wix/yoshi-flow-editor'
import isAfter from 'date-fns/isAfter'
import {Text} from 'wix-ui-tpa/cssVars'
import {useStyles} from '@wix/tpa-settings/react'
import {setHMToDate} from '@wix/table-reservations-lib/schedule'

import {stylesParams} from '../../stylesParams'
import {TimeSlotsByDate} from '../../../../types/TimeSlots'
import {TagsList} from '../TagsList'
import {getMediumDate} from '../../../../utils/dateString'
import {SelectTimeSlotError} from '../SelectTimeSlotError'
import {LayoutSize} from '../../../../components-shared/LayoutSizeProvider/types'
import {NotAvailableTimeSlotNotification} from '../NotAvailableTimeSlotNotification'
import {TimeOption} from '../../../../utils/timeOptions'

import {st, classes} from './TimeSlotsListExtended.st.css'
import {timeSlotsListExtendedDataHooks} from './constants'

interface Props {
  regionalSettings?: string
  items: TimeSlotsByDate
  partySize: number
  selectedDate?: Date
  selectedTimeOption?: TimeOption
  selectedTimeSlot?: Date
  showSelectTimeSlotError: boolean
  showNoAvailableTimeSlotNotification: boolean
  onSelectTimeSlot: (item: TimeSlotsByDate[string][number]) => void
  layoutSize: LayoutSize
}

export const TimeSlotsListExtended: React.FC<Props> = ({
  layoutSize,
  regionalSettings,
  items = {},
  partySize,
  selectedDate,
  selectedTimeOption,
  selectedTimeSlot,
  showNoAvailableTimeSlotNotification,
  showSelectTimeSlotError,
  onSelectTimeSlot,
}) => {
  const styles = useStyles()
  const {t} = useTranslation()

  const dates = Object.keys(items)
  const [isFullyOpened, setIsFullyOpened] = useState<boolean>(dates?.length <= 3)
  const datesToShow = isFullyOpened ? dates : dates.slice(0, 3)

  const secondaryTitleTag =
    styles.get(stylesParams.generalSecondaryTitleFont).htmlTag ??
    styles.getDefaultValue(stylesParams.generalSecondaryTitleFont).htmlTag

  if (!Object.values(items)?.length) {
    return null
  }

  return (
    <div className={st(classes.root)} data-hook={timeSlotsListExtendedDataHooks.root()}>
      {showNoAvailableTimeSlotNotification && selectedDate && selectedTimeOption ? (
        <NotAvailableTimeSlotNotification
          date={setHMToDate(
            selectedDate,
            selectedTimeOption.data.hours,
            selectedTimeOption.data.minutes,
          )}
          partySize={partySize}
          regionalSettings={regionalSettings}
        />
      ) : null}

      {showSelectTimeSlotError && <SelectTimeSlotError />}

      <Text tagName={secondaryTitleTag} className={classes.secondaryTitle}>
        {t('uou-reservations.reservations.time-slot-other-dates.text')}
      </Text>

      {datesToShow
        .sort((a, b) => {
          const aDate = new Date(a)
          const bDate = new Date(b)

          return isAfter(aDate, bDate) ? 1 : -1
        })
        .map((key, index) => (
          <section className={classes.section} key={index}>
            <TagsList
              layoutSize={layoutSize}
              label={getMediumDate(new Date(key), regionalSettings)}
              timeSlots={items?.[key] ?? []}
              onSelectTimeSlot={onSelectTimeSlot}
              regionalSettings={regionalSettings}
              selectedTimeSlot={selectedTimeSlot}
              extended
            />
          </section>
        ))}

      {!isFullyOpened ? (
        <a
          href="#"
          className={classes.overdoneLink}
          onClick={(e) => {
            e.preventDefault()

            setIsFullyOpened(true)
          }}
        >
          {t('uou-reservations.reservations.time-slot-other-dates.cta')}
        </a>
      ) : null}
    </div>
  )
}
