import React from 'react'
import {BreadcrumbsItem} from 'wix-ui-tpa/dist/cssVars/types/components'
import {Breadcrumbs as TPABreadcrumbs} from 'wix-ui-tpa/cssVars'

import {useGetLayoutSize} from '../LayoutSizeProvider'

import {classes, st} from './styles.st.css'

interface BreadcrumbsProps {
  items: BreadcrumbsItem[]
  className?: string
}

export const Breadcrumbs = ({items, className}: BreadcrumbsProps) => {
  const {layoutSize} = useGetLayoutSize()

  return (
    <TPABreadcrumbs
      className={st('', {[layoutSize]: true}, className, classes.root)}
      items={items}
      showTrailOnMobileMode
    />
  )
}
