import {createSettingsParams, SettingsParamType} from '@wix/tpa-settings'

import {
  BreadcrumbsAlignment,
  ButtonAlignment,
  FormLayout,
  TextAlignment,
} from '../../../../../types/createStylesParams'

export type LayoutSettingsParams = {
  breadcrumbsAlignment: SettingsParamType.String
  textAlignment: SettingsParamType.String
  formLayout: SettingsParamType.String
  buttonAlignment: SettingsParamType.String
}

export const layoutSettingsParams = createSettingsParams<LayoutSettingsParams>({
  breadcrumbsAlignment: {
    type: SettingsParamType.String,
    getDefaultValue: () => BreadcrumbsAlignment.Default,
  },
  textAlignment: {
    type: SettingsParamType.String,
    getDefaultValue: () => TextAlignment.Center,
  },
  formLayout: {
    type: SettingsParamType.String,
    getDefaultValue: () => FormLayout.Strip,
  },
  buttonAlignment: {
    type: SettingsParamType.String,
    inheritDesktop: false,
    getDefaultValue: (params) =>
      params.isMobile ? ButtonAlignment.Stretch : ButtonAlignment.Center,
  },
})
