import {ControllerFlowAPI} from '@wix/yoshi-flow-editor'
import {ReservationLocation} from '@wix/ambassador-table-reservations-v1-reservation-location/types'
import {
  IWidgetControllerConfig,
  SiteStructure,
} from '@wix/native-components-infra/dist/src/types/types'
import {Status, TimeSlot} from '@wix/ambassador-table-reservations-v1-time-slot/types'
import {Member} from '@wix/ambassador-members-v1-member/types'
import {BreadcrumbsItem} from 'wix-ui-tpa/dist/cssVars/types/components'

import {reservationLocationsService} from '../../services/reservationLocationsService'
import {noop} from '../../utils/helpers'
import {getRegionalSettings} from '../../utils/flowAPI'
import {RequestStatus, wrapRequest} from '../../utils/wrapRequest'
import {createStorageProvider} from '../../utils/storageContext'
import {getReservationLocationsMock} from '../../editor/editorMocks/getReservationLocationsMock'
import {getTimeSlotsMock} from '../../editor/editorMocks/getTimeSlotsMock'
import {getDayTimeSlotsMock} from '../../editor/editorMocks/getDayTimeSlotsMock'
import {handleReservationDataMock} from '../../editor/editorMocks/getReservationMock'
import {timeSlotsService} from '../../services/timeSlotsService'
import {getReservationDataQueryParamsValidation} from '../../utils/queryParams'
import {getCurrentMember} from '../../services/memberService'
import {ApprovalTextEditorState} from '../../utils/constants'
import {getIsSiteBusinessPremium} from '../../services/premiumFeaturesService'
import {getBreadcrumbsConfig} from '../../utils/getBreadcrumbsConfig'
import {TimeSlotsByDate} from '../../types/TimeSlots'
import {QueryValidation} from '../../types/QueryValidation'

import {handleReservationData} from './actions/handleReservationData'

interface ReservationsData {
  regionalSettings?: string
  getReservationLocationsStatus: RequestStatus
  getTimeSlotsStatus: RequestStatus
  getDayTimeSlotsStatus: RequestStatus
  handleReservationDataStatus: RequestStatus
  getIsSiteBusinessPremiumStatus: RequestStatus
  metaSiteId?: string
  reservationLocations: ReservationLocation[]
  timeSlots: TimeSlotsByDate
  dayTimeSlots: TimeSlot[]
  selectedReservationLocationId?: string
  selectedPartySize?: number
  selectedDate?: string
  queryValidation: QueryValidation
  fitToContentHeight: boolean
  currentMember?: Member
  prefetchCurrentMemberStatus: RequestStatus
  approvalTextEditorState: ApprovalTextEditorState
  isSiteBusinessPremium: boolean
  breadcrumbsConfig: BreadcrumbsItem[]
}

interface ReservationsActions {
  getReservationLocations: () => Promise<ReservationLocation[] | undefined>
  getTimeSlots: (params: {
    reservationLocationId: string
    partySize: number
    date: Date
    timeSlotInterval?: number | null
    statuses: Status[]
    timeZone?: string | null
  }) => Promise<TimeSlotsByDate | undefined>
  getDayTimeSlots: (params: {
    reservationLocationId: string
    partySize: number
    date: Date
    timeSlotInterval?: number | null
    timeZone?: string | null
  }) => Promise<TimeSlot[] | undefined>
  handleReservationData: ReturnType<typeof handleReservationData>
  prefetchCurrentMember: () => Promise<Member | null | undefined>
  getIsSiteBusinessPremium: () => Promise<boolean | undefined>
}

export interface ReservationsStorage extends ReservationsData, ReservationsActions {}

const defaultReservationsStorage: ReservationsStorage = {
  getReservationLocationsStatus: RequestStatus.DEFAULT,
  getTimeSlotsStatus: RequestStatus.DEFAULT,
  getDayTimeSlotsStatus: RequestStatus.DEFAULT,
  handleReservationDataStatus: RequestStatus.DEFAULT,
  prefetchCurrentMemberStatus: RequestStatus.DEFAULT,
  getIsSiteBusinessPremiumStatus: RequestStatus.DEFAULT,
  reservationLocations: [],
  timeSlots: {},
  dayTimeSlots: [],
  getReservationLocations: noop,
  getDayTimeSlots: noop,
  getTimeSlots: noop,
  handleReservationData: noop,
  queryValidation: {
    startDate: true,
    partySize: true,
    reservationLocationId: true,
  },
  fitToContentHeight: true,
  prefetchCurrentMember: noop,
  approvalTextEditorState: ApprovalTextEditorState.unknown,
  isSiteBusinessPremium: false,
  getIsSiteBusinessPremium: noop,
  breadcrumbsConfig: [],
}

const {withStorageProvider, useStorage} = createStorageProvider(defaultReservationsStorage)

const initReservationsStorage = (
  flowAPI: ControllerFlowAPI,
  controllerConfig: IWidgetControllerConfig,
  siteStructure: SiteStructure,
): ReservationsStorage => {
  const query = flowAPI.controllerConfig?.wixCodeApi?.location?.query || {}
  const queryValidation = getReservationDataQueryParamsValidation(query, true)

  return {
    ...defaultReservationsStorage,
    queryValidation,
    metaSiteId: controllerConfig?.platformAPIs?.bi?.metaSiteId,
    regionalSettings: getRegionalSettings(flowAPI),
    selectedReservationLocationId: query?.reservationLocationId,
    selectedPartySize:
      query?.reservationLocationId && query?.partySize ? Number(query.partySize) : undefined,
    selectedDate: query?.startDate,
    breadcrumbsConfig: getBreadcrumbsConfig(flowAPI, siteStructure),
    getReservationLocations: wrapRequest(
      flowAPI,
      reservationLocationsService.getReservationLocations,
      'reservationLocations',
      'getReservationLocationsStatus',
    ),
    getDayTimeSlots: wrapRequest(
      flowAPI,
      timeSlotsService.getDayTimeSlots,
      'dayTimeSlots',
      'getDayTimeSlotsStatus',
    ),
    getTimeSlots: wrapRequest(
      flowAPI,
      timeSlotsService.getTimeSlots,
      'timeSlots',
      'getTimeSlotsStatus',
    ),
    handleReservationData: handleReservationData(flowAPI),
    prefetchCurrentMember: wrapRequest(
      flowAPI,
      getCurrentMember,
      'currentMember',
      'prefetchCurrentMemberStatus',
    ),
    getIsSiteBusinessPremium: wrapRequest(
      flowAPI,
      getIsSiteBusinessPremium,
      'isSiteBusinessPremium',
      'getIsSiteBusinessPremiumStatus',
    ),
  }
}

const mockReservationsStorage = (
  flowAPI: ControllerFlowAPI,
  controllerConfig: IWidgetControllerConfig,
  siteStructure: SiteStructure,
): ReservationsStorage => {
  const initiatedStorage = initReservationsStorage(flowAPI, controllerConfig, siteStructure)

  return {
    ...initiatedStorage,
    getReservationLocations: wrapRequest(
      flowAPI,
      getReservationLocationsMock,
      'reservationLocations',
      'getReservationLocationsStatus',
    ),
    getTimeSlots: wrapRequest(flowAPI, getTimeSlotsMock, 'timeSlots', 'getTimeSlotsStatus'),
    getDayTimeSlots: wrapRequest(
      flowAPI,
      getDayTimeSlotsMock,
      'dayTimeSlots',
      'getDayTimeSlotsStatus',
    ),
    handleReservationData: handleReservationDataMock(flowAPI),
  }
}

export {
  initReservationsStorage,
  mockReservationsStorage,
  withStorageProvider as withReservationsStorageProvider,
  useStorage as useReservationsStorage,
}
