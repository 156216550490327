import {createSettingsParams, SettingsParamType} from '@wix/tpa-settings'

export enum ShowSlotsBehavior {
  All = 'all',
  Available = 'available',
}

export type DisplaySettingsParams = {
  showTitle: SettingsParamType.Boolean
  showDescription: SettingsParamType.Boolean
  showSlotsBehavior: SettingsParamType.String
  showBreadcrumbs: SettingsParamType.Boolean
}

export const DEFAULT_DISPLAY_SETTINGS_PARAMS = {
  showTitle: true,
  showDescription: true,
  showSlotsBehavior: ShowSlotsBehavior.All,
  showBreadcrumbs: true,
}

export const displaySettingsParams = createSettingsParams<DisplaySettingsParams>({
  showTitle: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => DEFAULT_DISPLAY_SETTINGS_PARAMS.showTitle,
  },
  showDescription: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => DEFAULT_DISPLAY_SETTINGS_PARAMS.showDescription,
  },
  showSlotsBehavior: {
    type: SettingsParamType.String,
    getDefaultValue: () => DEFAULT_DISPLAY_SETTINGS_PARAMS.showSlotsBehavior,
  },
  showBreadcrumbs: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => DEFAULT_DISPLAY_SETTINGS_PARAMS.showBreadcrumbs,
  },
})
