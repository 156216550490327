import {utcToZonedTime} from '@wix/table-reservations-lib/timezone'
import {ReservationLocation} from '@wix/ambassador-table-reservations-v1-reservation-location/types'

import {QueryValidation} from '../types/QueryValidation'

export const getPreselectedDate = ({
  selectedReservationLocation,
  preselectedReservationLocationId,
  preselectedDate,
  queryValidation,
}: {
  selectedReservationLocation?: ReservationLocation
  preselectedReservationLocationId?: string
  preselectedDate?: string
  queryValidation: QueryValidation
}) => {
  const preselectedDateForPreselectedReservationLocation =
    selectedReservationLocation?.id === preselectedReservationLocationId
      ? preselectedDate
      : undefined

  const isPreselectedDateValid =
    selectedReservationLocation?.id === preselectedReservationLocationId
      ? queryValidation.startDate
      : undefined

  return preselectedDateForPreselectedReservationLocation && isPreselectedDateValid
    ? utcToZonedTime(
        preselectedDateForPreselectedReservationLocation,
        selectedReservationLocation?.location?.timeZone,
      )
    : undefined
}
